import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { withStyles } from "@material-ui/core"
import Helmet from 'react-helmet';

const styles = theme => ({

})

function ServiceTemplate({ data, classes }) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <Helmet>
        <title>{frontmatter.title} - QHFS Limited</title>
      </Helmet>
      <h2>{frontmatter.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: html }}/>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { path: { eq: $slug } }) {
      html
      frontmatter {
        path
        title
        image
        bullets
      }
    }
  }
`

ServiceTemplate.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ServiceTemplate)